<template>
    <v-app id="inspire">
        <v-container
             class="fill-height"
             fluid
             :style="`
                background: linear-gradient( rgba(5, 30, 51, .4) 100%, rgba(5, 30, 51, .4) 100%), url(${bgAuth});
                background-repeat: no-repeat;
                background-position: top;
                background-size: cover;
             `"
        >
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-0 pa-7" style="background-color: rgba(255,255,255,0.91)">
                        <h1 class="pm-title">
                            <img :src="logo" alt="">
                            TT FREIGHT Dispatcher
                        </h1>
                        <p class="pm-text">Enter your email and password to get access to system.</p>
                        <v-card-text>

                            <v-form @submit.prevent="authLoad"
                                ref="form"
                                v-model="valid"
                                autocomplete="anyrandomstring">
                                <v-text-field class="mb-8" color="color1" type="text"
                                    v-model="email" dense
                                    :rules="emailRules"
                                    :error="error" hide-details
                                    label="Email" autocomplete="anyrandomstring"
                                    outlined clearable required />

                                <v-text-field class="mb-14" color="color1" type="password"
                                    v-model="password" dense
                                    :rules="notEmptyRule"
                                    :error="error" hide-details
                                    label="Password" autocomplete="anyrandomstring"
                                    outlined clearable required />

                                <v-btn  class="mt-2" color="color1"
                                        block dark type="submit"
                                        :loading="pageLoader">
                                    Sign in
                                </v-btn>
                            </v-form>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar v-model="error" color="#D3363A" dark absolute bottom>
            <div class="text-center text-capitalize d-flex justify-space-between align-center px-8">
                {{ errorMsg }}
                <v-icon small @click="error=false">mdi-close</v-icon>
            </div>

        </v-snackbar>
    </v-app>
</template>

<script>
    import axios from "../utils/axios";
    import { setToken } from '../utils/localStorage';
    import logo from '@/assets/logo.svg';
    import bgAuth from '@/assets/bgAuth.jpg';
    export default {
        data: () => ({
            logo, bgAuth,
            pageLoader: false,
            loading: false,

            email: '',
            password: '',

            error: false,
            errorMsg: '',

            valid: false,
            emailRules: [
                v => !!v || 'E-mail required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            notEmptyRule: [v => !!v || 'Password required']
        }),
        methods: {
            async authLoad() {
                if(this.valid) {
                    try {
                        this.pageLoader = true;
                        const {data} = await axios('dispatchers/login/dispatcher', {
                            method: "POST",
                            data: {
                                email: this.email,
                                password: this.password
                            }
                        });
                        if(data.id) {
                            setToken(data);
                            await this.$router.replace('/');
                        } else {
                            this.error = true;
                            this.errorMsg = "Token not provided"
                        }
                    } catch (e) {
                        this.error = true;
                        this.errorMsg = "Email or Password incorrect"
                    }
                    finally {
                        this.pageLoader = false;
                    }
                }
            }
        },
        async mounted() {
            this.pageLoader = false;
        }
    }
</script>

<style scoped lang="scss">
    .loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pm-navbar {
        margin: 0 auto;
        max-width: 1344px;
    }
    .pm-form {
        display: block;
        margin: 0 auto;
        max-width: 420px;
    }
    .pm-title {
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #051E34;
        line-height: 1.2;
        letter-spacing: 0;
        img {
            width: 40px;
            margin-right: 10px;
        }
    }
    .pm-text {
        text-align: center;
        font-weight: 500;
        color: rgb(129, 129, 152);
        max-width: 630px;
        margin: 0 auto;
        margin-bottom: 1rem;
        letter-spacing: 0;
        line-height: 1.6;
        margin-top: 15px;
    }
</style>
